/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\empty\cube\index.tsx
 * @LastEditTime: 2024-08-27 15:19:06
 */
// import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useCallback } from "react";
import { CubeColors } from '../config/cubeconfig'
import styles from './index.module.scss';

//数组6*9 top bottom front right back left  top bottom 从上向下看依次排列 其他从外边看依次排列
const BaseCube = (props: any) => {
  const { rx, ry, rz, cubedata, shownum, scale = 0.5 } = props;
  const cubeRef = useRef(null);

  const [cubeWidth, setCubeWidth] = useState(1);

  useEffect(() => {
    // 监听窗口大小变化
    const handleResize = () => {
      if (cubeRef.current) {
        let cubeWidth = (cubeRef.current as any).offsetWidth * scale;
        console.log('cubeWidth', cubeWidth);
        setCubeWidth(cubeWidth);
      }
    };
    window.addEventListener('resize', handleResize);

    handleResize();
    return () => {
      // 清除监听
      window.removeEventListener('resize', handleResize);
    };
  }, [cubeRef.current,scale]);

  return (
    <div
      ref={cubeRef}
      style={{
        transform: `rotateX(${rx}deg) rotateY(${ry}deg) rotateZ(${rz}deg) translateX(${0}px) translateY(${0}px) translateZ(${cubeWidth / 2}px)`
      }}
      className={styles.cube}>
      <div className={styles.top}
        style={{
          width: `${cubeWidth}px`,
          height: `${cubeWidth}px`,
          transform: `translateY(-${0.5 * cubeWidth}px) translateZ(-${0.5 * cubeWidth}px) rotateX(90deg)`
        }}
      >
        {cubedata[0].map((item: number, index: number) => (
          <div
            key={`top${index}`}
            className={styles.grid}
            style={{ backgroundColor: CubeColors[item % 7] }}>
            {shownum && (index + 1)}
          </div>
        ))}
      </div>

      <div className={styles.bottom}
        style={{
          width: `${cubeWidth}px`,
          height: `${cubeWidth}px`,
          transform: `translateY(${0.5 * cubeWidth}px) translateZ(${-0.5 * cubeWidth}px)  rotateX(90deg)`
        }}
      >
        {cubedata[1].map((item: number, index: number) => (
          <div
            key={`bottom${index}`}
            className={styles.grid}
            style={{ backgroundColor: CubeColors[item % 7] }}>
            {shownum && (index + 1)}
          </div>
        ))}
      </div>

      <div className={styles.front}
        style={{
          width: `${cubeWidth}px`,
          height: `${cubeWidth}px`,
        }}>
        {cubedata[2].map((item: number, index: number) => (
          <div
            key={`front${index}`}
            className={styles.grid}
            style={{ backgroundColor: CubeColors[item % 7] }}>
            {shownum && (index + 1)}
          </div>
        ))}
      </div>
      <div className={styles.right}
        style={{
          width: `${cubeWidth}px`,
          height: `${cubeWidth}px`,
          transform: `translateX(${0.5 * cubeWidth}px) translateZ(-${0.5 * cubeWidth}px) rotateY(90deg)`
        }}
      >
        {cubedata[3].map((item: number, index: number) => (
          <div
            key={`right${index}`}
            className={styles.grid}
            style={{ backgroundColor: CubeColors[item % 7] }}>
            {shownum && (index + 1)}
          </div>
        ))}
      </div>
      <div className={styles.back}
        style={{
          width: `${cubeWidth}px`,
          height: `${cubeWidth}px`,
          transform: `translateZ(-${1 * cubeWidth}px) rotateY(-180deg)`
        }}
      >
        {cubedata[4].map((item: number, index: number) => (
          <div
            key={`back${index}`}
            className={styles.grid}
            style={{ backgroundColor: CubeColors[item % 7] }}>
            {shownum && (index + 1)}
          </div>
        ))}
      </div>
      <div className={styles.left}
        style={{
          width: `${cubeWidth}px`,
          height: `${cubeWidth}px`,
          transform: `translateX(-${0.5 * cubeWidth}px) translateZ(-${0.5 * cubeWidth}px) rotateY(-90deg)`
        }}>
        {cubedata[5].map((item: number, index: number) => (
          <div
            key={`top${index}`}
            className={styles.grid}
            style={{ backgroundColor: CubeColors[item % 7] }}>
            {shownum && (index + 1)}
          </div>
        ))}
      </div>

    </div>
  )
};

export default BaseCube;