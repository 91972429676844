/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\empty\cube\index.tsx
 * @LastEditTime: 2024-08-27 15:19:06
 */
// import PropTypes from 'prop-types';
import { Button, Input, InputNumber, message, Modal } from "antd";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-use";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { LuckyWheel } from '@lucky-canvas/react';
import styles from './index.module.scss';
import _ from "lodash";
import { reqGetRealTimeData, reqSaveRealTimeData } from "api/api";
import moment from "moment";
import { log } from "console";


const defaultPrizeInfo = {
  title: '抽奖',
  usecount: 0,
  prizes: ['奖品一', '奖品二', '奖品三', '奖品四', '奖品五', '奖品六', '奖品七', '奖品八'],
};
const colors = ['#ffa39e', '#40a9ff', '#73d13d', '#f759ab', '#ff9c6e', '#fadb14', '#f5222d', '#c41d7f'];

const objName = 'test';

const Prize = (props: any) => {
  const myLucky = useRef();
  const [prizeInfo, setPrizeInfo] = useState<any>(defaultPrizeInfo);
  const [editPrizeInfo, setEditPrizeInfo] = useState<any>(defaultPrizeInfo);
  const [allCount, setAllCount] = useState(0);
  const [editAllCount, setEditAllCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);

  const query = new URLSearchParams(useLocation().search);
  const debug = query.get("debug");
  const folderId = query.get("folderId") || 'default';
  const userId = query.get("userId") || 'qf';

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const param = {
      objName,
      folderId,
      userId,
    }
    const res = await reqGetRealTimeData(param);
    if (!_.isNull(res.data)) {
      setPrizeInfo(res.data.jsonData);
      setAllCount(res.data.countData);
    }
  }

  const handleStart = () => {
    if (allCount < prizeInfo.usecount) {
      return;
    }
    let lucky: any = myLucky?.current;
    lucky.play()
    setTimeout(() => {
      const index = Math.random() * 6 >> 0;
      lucky.stop(index)
    }, 2500)
  }

  const handleEnd = async (prize: any) => {
    message.success(`恭喜你抽到:${prize.fonts[0].text}`);
    if (prizeInfo.usecount>0){
      const param = {
        countTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        objName,
        folderId,
        userId,
        countData: allCount-prizeInfo.usecount,
      }
      let res = await reqSaveRealTimeData(param);
      console.log(res);
      await loadData();
    }
  }

  const handleSet = () => {
    setEditPrizeInfo(_.cloneDeep(prizeInfo));
    setEditAllCount(allCount);
    setModalVisible(true);
  }

  const handleOk = async () => {
    const param = {
      countTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      objName,
      folderId,
      userId,
      countData: editAllCount,
      jsonData: editPrizeInfo,
    }
    console.log(param);
    
    let res = await reqSaveRealTimeData(param);
    console.log(res);
    await loadData();
    setModalVisible(false);
  }

  const handleCancel = () => {
    setModalVisible(false);
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {prizeInfo.title}
      </div>
      <LuckyWheel
        ref={myLucky}
        width="300px"
        height="300px"
        blocks={[{ padding: '10px', background: '#869cfa' }]}
        prizes={prizeInfo.prizes.map((item: any, index: number) => {
          return {
            background: colors[index % colors.length],
            fonts: [{ text: item, top: "5px" }]
          }
        })}
        buttons={[
          { radius: '40%', background: '#617df2' },
          { radius: '35%', background: '#afc8ff' },
          {
            radius: '30%', background: '#869cfa',
            pointer: true,
            fonts: [{ text: '开始', top: '-10px', fontColor: allCount >= prizeInfo.usecount ? "black" : "gray" }]
          }
        ]}
        onStart={handleStart}
        onEnd={handleEnd}
      />
      {
        prizeInfo.usecount > 0 && (
          <div className={styles.detailtitle}>
            <div> {`每次消耗:${prizeInfo.usecount}`}</div>
            <div> {`总共剩余:${allCount}`}</div>           
          </div>
        )
      }
      {
        debug && (
          <Button style={{ margin: "30px" }} type="primary" onClick={handleSet}>设置</Button>
        )
      }

      <Modal title='请选择结果'
        okText="确认"
        cancelText="取消"
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}>
        <div className={styles.modal}>
          <div className={styles.line}>
            <span className={styles.title}>标题:</span>
            <Input style={{ width: "300px" }}
              value={editPrizeInfo.title}
              onChange={e => {
                setEditPrizeInfo({
                  ...editPrizeInfo,
                  title: e.target.value
                })
              }} />
          </div>
          <div className={styles.line}>
            <span className={styles.title}>奖品:</span>
            <div className={styles.item}>
              {
                editPrizeInfo.prizes.map((item: string, index: number) => (
                  <Input key={`prize${index}`}
                    style={{ width: "300px" }}
                    value={item}
                    onChange={e => {
                      let tempValue = _.cloneDeep(editPrizeInfo);
                      tempValue.prizes[index] = e.target.value;
                      setEditPrizeInfo(tempValue);
                    }}
                    addonAfter={<MinusOutlined onClick={() => {
                      let tempValue = _.cloneDeep(editPrizeInfo);
                      tempValue.prizes.splice(index, 1);
                      setEditPrizeInfo(tempValue);
                    }} />} />
                ))
              }
              <Button icon={<PlusOutlined />}
                onClick={() => {
                  let tempValue = _.cloneDeep(editPrizeInfo);
                  tempValue.prizes.push('');
                  setEditPrizeInfo(tempValue);
                }} />
            </div>
          </div>
          <div className={styles.line}>
            <span className={styles.title}>每次消费:</span>
            <InputNumber
              style={{ width: "300px" }}
              min={0}
              max={100}
              value={editPrizeInfo.usecount}
              onChange={e => {
                setEditPrizeInfo({
                  ...editPrizeInfo,
                  usecount: e
                })
              }} />
          </div>
          <div className={styles.line}>
            <span className={styles.title}>当前次数:</span>
            <InputNumber
              style={{ width: "300px" }}
              min={0}
              max={100}
              value={editAllCount}
              onChange={e => {
                setEditAllCount(e || 0);
              }} />
          </div>
        </div>
      </Modal>
    </div>
  )
};

export default Prize;