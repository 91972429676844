import _ from "lodash";

export const CubeColors = ["gray", "yellow", "white", "blue", "red", "green", "orange"];

export const getPllColor = (index: number) => {
  return CubeColors[(index + 3) % 7];
}

export const BaseCubeData = [
  [1, 1, 1, 1, 1, 1, 1, 1, 1],
  [2, 2, 2, 2, 2, 2, 2, 2, 2],
  [3, 3, 3, 3, 3, 3, 3, 3, 3],
  [4, 4, 4, 4, 4, 4, 4, 4, 4],
  [5, 5, 5, 5, 5, 5, 5, 5, 5],
  [6, 6, 6, 6, 6, 6, 6, 6, 6],
]

// data [x,y]  x=>绿 橙 蓝 红 y=>逆时针顺序  value=>0:blue 1:red 2:green 3:orange
//初始状态为：
// [                              0 0 0
//   [2, 2, 2],                 1       3
//   [3, 3, 3],                 1       3
//   [0, 0, 0],                 1       3
//   [1, 1, 1]                    2 2 2   
// ]

//将beforeData的状态按pllData的公式来还原，返回还原后的状态
//逻辑是： pllData->DefaultPllData 与 beforeData->afterData 的还原步骤一样
//因此只用按照pllData->DefaultPllData数组中各元素的位置变化，将beforeData按相同的位置变化即可
//由于中层只能在中层移动，角块在逆时针的起始位，无论如何旋转，必定在其他方向的起始位
//根据上述逻辑判定，Data中的数据只会在数组中竖向移动位置。
export const TurnPllData = (beforeData: any, pllData: any) => {
  let afterData = _.cloneDeep(DefaultPllData);
  for (let i = 0; i < 4; i++) {
    for (let j = 0; j < 3; j++) {
      let tempValue = DefaultPllData[i][j];
      let rowIndex = pllData.findIndex((item: any) => item[j] === tempValue);
      if (rowIndex !== -1) {
        afterData[i][j] = beforeData[rowIndex][j];
      }
    }
  }
  return afterData;
}

//pll数据转成3DCube数据
export const PllDataTurnCubeData=(pllData:any)=>{
  const cubeData= _.cloneDeep(BaseCubeData);
  for (let i = 0; i < pllData.length; i++) {
    for (let j = 0; j < pllData[i].length; j++) {
      let tempnum = pllData[i][j];
      cubeData[2 + i][j] = tempnum + 3;
    }
  }
  return cubeData;
}

export const DefaultPllData = [
  [2, 2, 2],
  [3, 3, 3],
  [0, 0, 0],
  [1, 1, 1]
]

export const PllDatas = [{
  title: 'Ua',
  index: 1,
  data: [
    [2, 2, 2],
    [3, 0, 3],
    [0, 1, 0],
    [1, 3, 1]
  ]
}, {
  title: 'Ub',
  index: 2,
  data: [
    [2, 2, 2],
    [3, 1, 3],
    [0, 3, 0],
    [1, 0, 1]
  ]
}, {
  title: 'H',
  index: 3,
  data: [
    [2, 0, 2],
    [3, 1, 3],
    [0, 2, 0],
    [1, 3, 1]
  ]
}, {
  title: 'Z',
  index: 4,
  data: [
    [3, 0, 3],
    [0, 3, 0],
    [1, 2, 1],
    [2, 1, 2]
  ]
  // data: [
  //   [1, 0, 1],
  //   [2, 3, 2],
  //   [3, 2, 3],
  //   [0, 1, 0]
  // ]
}, {
  title: 'Aa',
  index: 5,
  data: [
    [2, 2, 0],
    [1, 3, 2],
    [3, 0, 3],
    [0, 1, 1]
  ]
}, {
  title: 'Ab',
  index: 6,
  data: [
    [2, 2, 3],
    [0, 3, 0],
    [1, 0, 2],
    [3, 1, 1]
  ]
}, {
  title: 'E',
  index: 7,
  data: [
    [1, 2, 3],
    [0, 3, 2],
    [3, 0, 1],
    [2, 1, 0]
  ]
}, {
  title: 'T',
  index: 8,
  data: [
    [2, 2, 3],
    [0, 1, 2],
    [3, 0, 0],
    [1, 3, 1]
  ]
}, {
  title: 'F',
  index: 9,
  data: [
    [2, 0, 3],
    [0, 3, 2],
    [3, 2, 0],
    [1, 1, 1]
  ]
}, {
  title: 'V',
  index: 10,
  data: [
    [2, 2, 0],
    [1, 0, 3],
    [0, 3, 2],
    [3, 1, 1]
  ]
}, {
  title: 'Y',
  index: 11,
  data: [
    [2, 2, 0],
    [1, 3, 3],
    [0, 1, 2],
    [3, 0, 1]
  ]
}, {
  title: 'Ja',
  index: 12,
  data: [
    [2, 2, 3],
    [0, 0, 0],
    [1, 1, 2],
    [3, 3, 1]
  ]
  // data: [
  //   [3, 3, 0],
  //   [1, 1, 1],
  //   [2, 2, 3],
  //   [0, 0, 2]
  // ]
}, {
  title: 'Jb',
  index: 13,
  data: [
    [1, 2, 2],
    [3, 1, 1],
    [2, 3, 3],
    [0, 0, 0]
  ]
}, {
  title: 'Rb',
  index: 14,
  data: [
    [2, 3, 2],
    [3, 2, 0],
    [1, 0, 3],
    [0, 1, 1]
  ]
  // data: [
  //   [1, 2, 1],
  //   [2, 1, 3],
  //   [0, 3, 2],
  //   [3, 0, 0]
  // ]
}, {
  title: 'Ra',
  index: 15,
  data: [
    [2, 2, 3],
    [0, 3, 2],
    [3, 1, 0],
    [1, 0, 1]
  ]
  // data: [
  //   [1, 1, 2],
  //   [3, 2, 1],
  //   [2, 0, 3],
  //   [0, 3, 0]
  // ]
}, {
  title: 'Gc',
  index: 16,
  data: [
    [2, 2, 3],
    [0, 1, 0],
    [1, 3, 2],
    [3, 0, 1]
  ]
  // data: [
  //   [2, 0, 3],
  //   [0, 1, 2],
  //   [3, 3, 0],
  //   [1, 2, 1]
  // ]
}, {
  title: 'Gd',
  index: 17,
  data: [
    [2, 1, 3],
    [0, 0, 2],
    [3, 2, 0],
    [1, 3, 1]
  ]
}, {
  title: 'Ga',
  index: 18,
  data: [
    [2, 3, 3],
    [0, 1, 2],
    [3, 2, 0],
    [1, 0, 1]
  ]
}, {
  title: 'Gb',
  index: 19,
  data: [
    [2, 0, 3],
    [0, 2, 2],
    [3, 1, 0],
    [1, 3, 1]
  ]
}, {
  title: 'Na',
  index: 20,
  data: [
    [0, 2, 2],
    [3, 1, 1],
    [2, 0, 0],
    [1, 3, 3]
  ]
}, {
  title: 'Nb',
  index: 21,
  data: [
    [2, 2, 0],
    [1, 1, 3],
    [0, 0, 2],
    [3, 3, 1]
  ]
},
]