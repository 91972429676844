/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-22 15:06:27
 * @FilePath: \website\src\index.tsx
 * @LastEditTime: 2024-08-28 17:05:45
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from "antd";
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router, Switch, Route, useHistory
} from "react-router-dom";
import Main from './pages/main';
import PageEdit from './pages/pageedit';
import PageList from './pages/pagelist';
import StationList from './pages/stationlist';
import Empty from './pages/empty';
import Version from './pages/version';
import Manager from './pages/manager';
import Login from './pages/login';
import JsonEdit from './pages/jsonedit';
import { Provider } from 'react-redux';
import store from './store';

import 'rc-texty/assets/index.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import PllCube from 'pages/empty/cube/pllcube';
import PllCube1 from 'pages/empty/cube/pllcube1';
import Prize from 'pages/empty/cube/prize';

ReactDOM.render(
  // 严格模式
  // <React.StrictMode>  
  <ConfigProvider>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/pageedit" exact>
            <PageEdit />
          </Route>
          <Route path="/jsonedit" exact>
            <JsonEdit />
          </Route>
          <Route path="/pagelist">
            <PageList />
          </Route>
          <Route path="/empty">
            <Empty />
          </Route>
          <Route path="/pllcube">
            <PllCube />
          </Route>
          <Route path="/pllcube1">
            <PllCube1 />
          </Route>
          <Route path="/prize">
            <Prize />
          </Route>
          <Route path="/version">
            <Version />
          </Route>
          <Route path="/manager">
            <Manager />
          </Route>
          <Route path="/" >
            <Main />
          </Route>
        </Switch>
      </Router>
    </Provider>
  </ConfigProvider>
  // </React.StrictMode>
  , document.getElementById("root")
);

reportWebVitals();