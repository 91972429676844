/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\empty\cube\pllcube1\index.tsx
 * @LastEditTime: 2024-10-20 22:17:40
 */
// import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-use";
import {
  Tooltip, Modal, Form, Input, Select, Avatar, Menu, Carousel, Image, Button,
  ConfigProvider, Popover, Statistic, InputNumber, Checkbox, Dropdown, Progress, message, Radio
} from 'antd';
import moment from "moment";
import _ from 'lodash';
import styles from './index.module.scss';
import { BaseCubeData, DefaultPllData, PllDatas, PllDataTurnCubeData, TurnPllData } from "../config/cubeconfig";
import PlantCube from "../pllcube/plantcube";
import { countdownValueType } from "antd/lib/statistic/utils";
import BaseCube from "../basecube";
import { reqGetRealTimeData, reqSaveRealTimeData } from "api/api";
import PllSet from "../pllcube/pllset";
import { log } from "console";

const { Countdown } = Statistic;
// const deadline = Date.now() + 1000 * 30; // Moment is also OK

const coinUrl = "https://infomedia-image.oss-cn-beijing.aliyuncs.com/muzhicloud/9208750090/user/2024-10-19/052d9d35-7ea0-9e26-647e-83a2a640f44f.jpg";
const cardUrl = "https://infomedia-image.oss-cn-beijing.aliyuncs.com/muzhicloud/9208750090/user/2024-10-19/9016d662-9e02-41c8-7636-a245625a9b04.jpg";
const childUrl = "https://infomedia-image.oss-cn-beijing.aliyuncs.com/muzhicloud/9208750090/user/2024-11-05/6386ccc4-e82e-e8b5-a684-abb59eec0818.gif";
const fireworkUrl = "https://infomedia-image.oss-cn-beijing.aliyuncs.com/muzhicloud/9208750090/user/2024-11-05/fcbc000a-04f9-22c6-2e65-f9eeb06ee7af.gif";

// const LevelData = [2.3, 2.15, 2.0, 1.85, 1.75];
const PllCount = 20;

//关卡信息
const LevelDatas = [
  {
    level: 1,  //等级
    preDuration: [2.3, 2.15, 2.0, 1.95, 1.85], //每个需要的时间
    addTime: 0.3, //加成时间
    showMode: 'plant',  //plant or base  平面或六格
    needTurnBefore: false, //pll之前需要旋转
    needTurnAfter: false, //pll之后需要旋转
    tip: true,
    coins: 1,
  },
  {
    level: 2,  //等级
    preDuration: [2.3, 2.15, 2.0, 1.95, 1.85], //每个需要的时间
    addTime: 1, //加成时间
    showMode: 'plant',  //plant or base  平面或六格
    needTurnBefore: true, //pll之前需要旋转
    needTurnAfter: false, //pll之后需要旋转
    tip: false,
    coins: 2,
  },
  {
    level: 3,  //等级
    preDuration: [2.3, 2.15, 2.0, 1.95, 1.85], //每个需要的时间
    addTime: 1.3, //加成时间
    showMode: 'plant',  //plant or base  平面或六格
    needTurnBefore: true, //pll之前需要旋转
    needTurnAfter: true, //pll之后需要旋转
    tip: false,
    coins: 2,
  },
  {
    level: 4,  //等级
    preDuration: [2.3, 2.15, 2.0, 1.95, 1.85], //每个需要的时间
    addTime: 1, //加成时间
    showMode: 'base',  //plant or base  平面或六格
    needTurnBefore: false, //pll之前需要旋转
    needTurnAfter: false, //pll之后需要旋转
    tip: false,
    coins: 3,
  },
  {
    level: 5,  //等级
    preDuration: [2.3, 2.15, 2.0, 1.95, 1.85], //每个需要的时间
    addTime: 1.5, //加成时间
    showMode: 'base',  //plant or base  平面或六格
    needTurnBefore: true, //pll之前需要旋转
    needTurnAfter: false, //pll之后需要旋转
    tip: false,
    coins: 3,
  },
  {
    level: 6,  //等级
    preDuration: [2.3, 2.15, 2.0, 1.95, 1.85], //每个需要的时间
    addTime: 1.8, //加成时间
    showMode: 'base',  //plant or base  平面或六格
    needTurnBefore: true, //pll之前需要旋转
    needTurnAfter: true, //pll之后需要旋转
    tip: false,
    coins: 3,
  },
]

const PlayModes = [
  { label: '挑战', value: 'game' },
  { label: '练习', value: 'train' },
]

const PllCube1 = () => {
  const [playMode, setPlayMode] = useState('game');  //模式 game,train
  const [mainLevel, setMainLevel] = useState<number>(1); //1-3
  const [detailLevel, setDetailLevel] = useState(1); //1-5
  const [coinCount, setCoinCount] = useState(0);  //硬币
  const [cardCount, setCardCount] = useState(0);  //卡
  const [start, setStart] = useState(false);
  const [duration, setDuration] = useState(0);  //当前已经拼了多少时间
  const [allDuration, setAllDuration] = useState(1);  //秒  全部占用时间
  const [deadline, setDeadline] = useState<any>(moment());
  const [gamePlldatas, setGamePlldatas] = useState<any>([]);  //生成的pll数据
  const [testPlldatas, setTestPlldatas] = useState<any>([]);  //弹出框中的检测数据
  const [testResult, setTestResult] = useState(0);  //最终结果index
  const [selectedIndex, setSelectedIndex] = useState(-1); //选择的结果
  const [resData, setResData] = useState<any>(DefaultPllData);
  const [cubedata, setcubedata] = useState(BaseCubeData);
  const [levelData, setLevelData] = useState(LevelDatas[0]);  //当前的Level信息

  const [screenWidth, setScreenWidth] = useState(window.innerWidth); //当前窗口的宽度
  const [screenHeight, setScreenHeight] = useState(window.innerHeight); //当前窗口的高度

  const [showFire, setShowFire] = useState(false);
  const [resultModalVisible, setResultModalVisible] = useState(false);
  const [showSet, setShowSet] = useState(false); //设置
  const [chooseDatas, setChooseDatas] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21]);

  const query = new URLSearchParams(useLocation().search);
  const debug = query.get("debug");


  useEffect(() => {
    loadData();

    // 监听窗口大小变化
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      // 清除监听
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const loadData = async () => {
    const param = {
      objName: 'test',
      folderId: 'pllcube1',
      userId: 'qf',
    }
    const res = await reqGetRealTimeData(param);
    console.log(res);

    if (!_.isNull(res.data)) {
      setCardCount(res.data.countData || 0);
      setCoinCount(parseInt(res.data?.jsonData?.coinCount || '0'));
    }
  }

  //生成pll数据
  const getPllDatas = (leveldata: any) => {
    //随机生成20组pll数据
    let tempdatas = [];
    let afterPllData = _.cloneDeep(DefaultPllData);
    for (let i = 0; i < PllCount; i++) {
      // const randomNumber = Math.floor(Math.random() * 21); // 生成0到20之间的随机整数              
      // let pllData: any = _.cloneDeep(PllDatas[randomNumber]);

      //从chooseDatas中随机取一个
      const randomNumber = chooseDatas[Math.floor(Math.random() * chooseDatas.length)];
      let pllData: any = _.cloneDeep(PllDatas.find(item => item.index === randomNumber) || PllDatas[0]);

      pllData.beforeIndex = 0;
      pllData.afterIndex = 0;

      if (pllData.index === 1) {
        pllData.afterIndex = 2;  //U2
      }
      if (pllData.index === 4) {  //U
        pllData.afterIndex = 3;
      }
      if (pllData.index === 14) { //U`
        pllData.afterIndex = 1;
      }
      if (pllData.index === 15) { //U`
        pllData.afterIndex = 1;
      }

      //pll之前需要旋转 1->U`
      if (leveldata.needTurnBefore) {
        const turnBeforeIndex = Math.floor(Math.random() * 4);
        pllData.data = [...(pllData.data).slice(turnBeforeIndex), ...(pllData.data).slice(0, turnBeforeIndex)];
        pllData.beforeIndex = turnBeforeIndex;
      }

      //pll之后需要旋转 1->U`
      if (leveldata.needTurnAfter) {
        const turnAfterIndex = Math.floor(Math.random() * 4);
        pllData.data = pllData.data.map((x: any) => x.map((y: any) => (y + turnAfterIndex) % 4));
        pllData.afterIndex = (pllData.afterIndex + turnAfterIndex) % 4;
      }

      //处理之前旋转 和之后旋转的Title
      switch (pllData.beforeIndex) {
        case 1:
          pllData.beforeTitle = "U`";
          break;
        case 2:
          pllData.beforeTitle = "U2";
          break;
        case 3:
          pllData.beforeTitle = "U";
          break;
        default:
          pllData.beforeTitle = "";
          break;
      }

      switch (pllData.afterIndex) {
        case 1:
          pllData.afterTitle = "U`";
          break;
        case 2:
          pllData.afterTitle = "U2";
          break;
        case 3:
          pllData.afterTitle = "U";
          break;
        default:
          pllData.afterTitle = "";
          break;
      }

      //console.log('before',afterPllData, pllData.data);

      afterPllData = TurnPllData(afterPllData, pllData.data);
      //console.log('after',afterPllData);

      pllData.resData = _.cloneDeep(afterPllData);
      //console.log(pllData);
      pllData.cubeData = PllDataTurnCubeData(pllData.data);
      //console.log(pllData);
      tempdatas.push(pllData);
    }

    setGamePlldatas(tempdatas);
    console.log('gamePlldatas', tempdatas);

    //生成5组干扰答案
    let testResult: any[] = [];
    while (testResult.length < 5) {
      const randomNumber = Math.floor(Math.random() * 21); // 生成0到20之间的随机整数
      //不能重复
      if (JSON.stringify(PllDatas[randomNumber].data) !== JSON.stringify(afterPllData) &&
        !(testResult.map(item => JSON.stringify(item)).includes(JSON.stringify(PllDatas[randomNumber].data)))) {
        testResult.push(_.cloneDeep(PllDatas[randomNumber].data));
      }
    }
    // 将正确答案在随机位置插入  
    const randomIndex = Math.floor(Math.random() * (testResult.length + 1));
    testResult.splice(randomIndex, 0, afterPllData);
    console.log('testResult', testResult, randomIndex);
    setTestPlldatas(testResult);
    setTestResult(randomIndex);
    setSelectedIndex(-1);
  }

  const handleStart = () => {
    let tempLevelData = _.cloneDeep(LevelDatas[mainLevel - 1]);
    let duration = tempLevelData.preDuration[detailLevel - 1] + tempLevelData.addTime;;
    console.log('duration', duration);
    setLevelData(tempLevelData);  //??????????????
    const allDuration = (duration * PllCount);
    setAllDuration(Number(allDuration.toFixed(2)));
    setDeadline(moment().add(allDuration, 'seconds'));
    getPllDatas(tempLevelData);
    setStart(true);
  }

  const getDuration = () => {
    let duration = levelData.preDuration[detailLevel - 1] + levelData.addTime;
    return Number(duration.toFixed(2));
  }

  const handleStop = () => {
    setDeadline(null);
    setStart(false);
    if (playMode === 'game') {
      setResultModalVisible(true);
    }
    console.log(testResult);
  }

  const handleFinish = () => {
    setDeadline(null);
    setStart(false);
    if (playMode === 'game') {
      setResultModalVisible(true);
    }
    console.log(testResult);
  }

  const handleOk = async () => {
    setResultModalVisible(false);
    if (selectedIndex === testResult) {
      let param: any = {
        objName: 'test',
        folderId: 'pllcube1',
        userId: 'qf',
      }
      let res = await reqGetRealTimeData(param);
      let jsonData = _.cloneDeep(res.data.jsonData);
      let tempDetailLevel = detailLevel;
      let tempCoinCount = coinCount;
      let tempCardCount = res.data.countData;

      if (tempDetailLevel < 5) {
        //setDetailLevel(detailLevel + 1);
        tempDetailLevel++;
        message.success('恭喜你闯关成功,你爬呀爬呀爬上了一个新台阶');
      } else {
        tempDetailLevel = 1;
        tempCoinCount = tempCoinCount + levelData.coins;
        //10个币换一个卡
        if (tempCoinCount >= 10) {
          tempCardCount++;
          tempCoinCount = tempCoinCount - 10;
        }
        message.success(`恭喜你跳过台阶,获得宝藏：${levelData.coins}个金币`);
        //显示烟花
        setShowFire(true);
        // 设置一个定时器，在3秒后将 showFire 设置为 false
        setTimeout(() => {
          setShowFire(false);
        }, 3000);
      }

      setDetailLevel(tempDetailLevel);

      jsonData.coinCount = tempCoinCount;
      param.countTime = moment().format('YYYY-MM-DD HH:mm:ss');
      param.countData = tempCardCount;
      param.jsonData = jsonData;
      res = await reqSaveRealTimeData(param);
      await loadData();
    } else {
      message.error('闯关失败,你摔了个大跟头，请爬起来重新闯关');
    }
  }

  const handleCancel = () => {
    setResultModalVisible(false);
  }

  const handleSet = () => {
    setShowSet(true);
  }

  const randerStair = () => {
    let stairsArr = [
      [0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 1, 0],
      [0, 0, 0, 0, 1, 1, 0],
      [0, 0, 0, 1, 1, 1, 0],
      [0, 0, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 0],
      [0, 0, 0, 0, 0, 0, 0],
    ]
    stairsArr[5 - detailLevel][detailLevel] = 2;
    return (<div className={styles.leftcontainer}>
      {stairsArr.flatMap(row => row).map(item => {
        if (item === 1) {
          return (<div className={styles.stair} />)
        } else if (item == 2) {
          return (<div className={styles.child} style={{ backgroundImage: `url(${childUrl})` }} />)
        } else {
          return (<div />)
        }
      })}
    </div>)
  }

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.topbar}>
          <div className={styles.left}>
            <img src={coinUrl} className={styles.coin} />
            <span>×{coinCount}</span>
            <img src={cardUrl} className={styles.card}
              onClick={() => {
                window.location.href = '/prize?folderId=pllcube1';
              }} />
            <span>×{cardCount}</span>
          </div>
          <span className={styles.info}>
            作者：谷青峰、谷鹏飞
          </span>
        </div>
        <div className={styles.titleline}>
          PLL超级大挑战
        </div>
        <div className={styles.centercontainer}>
          {randerStair()}
          <div className={styles.rightcontainer}>
            <div className={styles.line}>
              <span className={styles.title}>模式：</span>
              <Radio.Group
                size="large"
                options={PlayModes}
                onChange={e => { !start && setPlayMode(e.target.value) }}
                value={playMode}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
            <div className={styles.line}>
              <span className={styles.title}>关卡：</span>
              <Radio.Group
                size="large"
                options={LevelDatas.map((item, index) => {
                  return {
                    label: `${item.level}`,
                    value: item.level,
                  }
                })}
                onChange={e => { !start && setMainLevel(e.target.value) }}
                value={mainLevel}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
            <div className={styles.line}>
              <Countdown prefix="倒计时："
                value={deadline}
                format="ss:SSS"
                onFinish={handleFinish}
                onChange={(value: any) => {
                  setDuration(allDuration * 1000 - value);
                }} />
            </div>
            <div className={styles.line}>
              <span className={styles.title}>计时：</span>
              {`${Math.round(duration / 1000)}/${allDuration}`}
            </div>
            <div className={styles.buttonbar}>
              <div>
                <Button type="primary" size="large" disabled={start} onClick={handleStart}>
                  开始
                </Button>
                <Button type="primary" size="large" disabled={!start} style={{ marginLeft: "30px" }} onClick={handleStop}>
                  结束
                </Button>
                <Button type="primary" size="large" disabled={start} style={{ marginLeft: "30px" }} onClick={handleSet}>
                  设置
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* <Progress percent={(allDuration * 1000 - duration) / (allDuration * 1000) * 100} /> */}
      </div>


      <div className={styles.plantcubebar}>
        {gamePlldatas
          .map((item: any, index: number) => (
            <div
              key={`plantcube${index}`}
              className={styles.pllitem}
              onClick={() => {
                console.log('item', item);

                setResData(item.resData);
              }}>
              <span className={styles.title}
                style={{ color: "black" }}>
                {(levelData.tip || playMode === "train") ?
                  `${item.index}.${item.beforeTitle && (item.beforeTitle + '+')}${item.title}${item.afterTitle && ('+' + item.afterTitle)}`
                  : `${index + 1}`}
              </span>
              <div className={styles.plantcube}
                style={screenWidth < screenHeight ? { width: "100%" } : { height: "100%" }}>
                {levelData.showMode === 'plant' ? (
                  <PlantCube data={item.data} />
                ) : (
                  <BaseCube
                    rx={-15}
                    ry={155}
                    rz={0}
                    scale={0.7}
                    cubedata={item.cubeData}
                  />
                )}
              </div>
              <div style={{
                width: `${(() => {
                  let prc = (duration - index * getDuration() * 1000) / (getDuration() * 1000) * 100;
                  if (prc < 0) {
                    prc = 0;
                  } else if (prc > 100) {
                    prc = 100;
                  }
                  return prc;
                })()}%`,
                height: "3px",
                backgroundColor: "red",
                alignSelf: "flex-start",
              }}></div>
            </div>
          ))}
        {playMode === "train" && (
          <div className={styles.pllitem}>
            <span className={styles.title}
              style={{ color: "black" }}>
              res
            </span>
            <div className={styles.plantcube}
              style={screenWidth < screenHeight ? { width: "100%" } : { height: "100%" }}>
              <PlantCube data={resData} />
              {/* <BaseCube
                rx={-15}
                ry={135}
                rz={0}
                scale={0.7}
                cubedata={cubedata}
              /> */}
            </div>
          </div>
        )}
      </div>
      {showFire && (<div className={styles.fire} />)}

      <Modal title='请选择结果'
        width={650}
        okText="确认"
        cancelText="取消"
        maskStyle={debug ? {} : { backgroundColor: "gray" }}
        visible={resultModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}>
        <div className={styles.modal}>
          {testPlldatas
            // .filter(item => chooseDatas.includes(item.index))
            .map((item: any, index: number) => (
              <div
                key={`testcube${index}`}
                className={`${styles.pllitem} ${index === selectedIndex ? styles.selected : ""}`}
                onClick={() => {
                  setSelectedIndex(index);
                }}>
                <PlantCube data={item} />
              </div>
            ))}
        </div>
      </Modal>

      <PllSet
        datas={chooseDatas}
        visible={showSet}
        onOk={(data: any) => {
          setShowSet(false);
          setChooseDatas(data);
        }}
        onCancel={() => {
          setShowSet(false);
        }} />
    </div>
  )
};

export default PllCube1;