/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2024-08-27 17:12:58
 * @FilePath: \website\src\pages\empty\cube\pllcube\plantcube\index.tsx
 * @LastEditTime: 2024-08-27 17:40:00
 */
import React, { useState, useEffect } from "react";
import { CubeColors, getPllColor } from "../../config/cubeconfig";
import styles from './index.module.scss';

const PlantCube = (props: any) => {
  const { data, hide } = props;

  useEffect(() => {
  }, []);

  return (
    <div className={styles.container}>
      {/* 第一行 */}
      <div className={styles.blank}></div>
      <div className={styles.horgrid} style={{ backgroundColor: hide ? "transparent" : getPllColor(data[2][2]) }}></div>
      <div className={styles.horgrid} style={{ backgroundColor: hide ? "transparent" : getPllColor(data[2][1]) }}></div>
      <div className={styles.horgrid} style={{ backgroundColor: hide ? "transparent" : getPllColor(data[2][0]) }}></div>
      <div className={styles.blank}></div>

      {/* 第二行 */}
      <div className={styles.vergrid} style={{ backgroundColor: hide ? "transparent" : getPllColor(data[3][0]) }}></div>
      <div className={styles.grid}></div>
      <div className={styles.grid}></div>
      <div className={styles.grid}></div>
      <div className={styles.vergrid} style={{ backgroundColor: getPllColor(data[1][2]) }}></div>

      {/* 第三行 */}
      <div className={styles.vergrid} style={{ backgroundColor: hide ? "transparent" : getPllColor(data[3][1]) }}></div>
      <div className={styles.grid}></div>
      <div className={styles.grid}></div>
      <div className={styles.grid}></div>
      <div className={styles.vergrid} style={{ backgroundColor: getPllColor(data[1][1]) }}></div>

      {/* 第四行 */}
      <div className={styles.vergrid} style={{ backgroundColor: hide ? "transparent" : getPllColor(data[3][2]) }}></div>
      <div className={styles.grid}></div>
      <div className={styles.grid}></div>
      <div className={styles.grid}></div>
      <div className={styles.vergrid} style={{ backgroundColor: getPllColor(data[1][0]) }}></div>

      {/* 第五行 */}
      <div className={styles.blank}></div>
      <div className={styles.horgrid} style={{ backgroundColor: getPllColor(data[0][0]) }}></div>
      <div className={styles.horgrid} style={{ backgroundColor: getPllColor(data[0][1]) }}></div>
      <div className={styles.horgrid} style={{ backgroundColor: getPllColor(data[0][2]) }}></div>
      <div className={styles.blank}></div>
    </div>
  )
};

export default PlantCube;