/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\empty\index.jsx
 * @LastEditTime: 2024-08-29 17:08:26
 */
// import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Tooltip, Modal, Form, Input, Select, Avatar, Menu, Carousel, Image, Button,
  ConfigProvider, Popover, Statistic, InputNumber, Checkbox, Dropdown, message
} from 'antd';
import { RightCircleOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import _, { constant } from 'lodash';
import UEditor, { getContent, setContent } from "components/ueditor/Ueditor";
import { SketchPicker } from 'react-color';
import type { MenuProps } from 'antd';
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import { OverPack, Element, Parallax } from 'rc-scroll-anim';
import Texty, { ITextyMode, ITextyType } from 'rc-texty';
import { Player } from 'video-react';
import * as Icon from '@ant-design/icons';
import { reqGetSignature, reqGetWorkSpaces } from 'api/api';
import ImageInput from 'components/common/imageinput';
import { templateRender, parseParam, urlEncode, dealParam, dealDateParam } from 'utils/common';
import styles from './index.module.scss';
import { AlignItems } from "components/container";
import { ajax, post } from 'api/ajax';
import { testData, lineData } from './data';
import { Column, Line } from '@ant-design/charts';
import { NewGuid, screenShotAndSaveAliOss } from 'utils/common';
import html2canvas from 'html2canvas';
import moment from "moment";
import BaseCube from './cube/basecube';
import PllCube from './cube/pllcube';
import { BaseCubeData } from './cube/config/cubeconfig';
import { setPageParam, setBaseParam, setUrlParam, setApiParam } from "store/slices/globalSlice";
import BaseCube1 from "./cube/prize";
import { LuckyWheel } from '@lucky-canvas/react';
import { showColors } from "utils/componentdefine";
// import ReactPlayer from 'react-player';

// var TweenOneGroup = TweenOne.TweenOneGroup;
const { TextArea } = Input;
const { Countdown } = Statistic;

const prizes = ['奖品一', '奖品二', '奖品三', '奖品四', '奖品五', '奖品六', '奖品七', '奖品八']

const Empty = () => {

  const [blocks] = useState([
    { padding: '10px', background: '#869cfa' }
  ])
  // const [prizes] = useState([
  //   { background: '#e9e8fe', fonts: [{ text: '大家好你好呀' }] },
  //   { background: '#b8c5f2', fonts: [{ text: '1' }] },
  //   { background: '#e9e8fe', fonts: [{ text: '2' }] },
  //   { background: '#b8c5f2', fonts: [{ text: '3' }] },
  //   { background: '#e9e8fe', fonts: [{ text: '4' }] },
  //   { background: '#b8c5f2', fonts: [{ text: '5' }] },
  //   { background: '#e9e8fe', fonts: [{ text: '6' }] },
  //   { background: '#b8c5f2', fonts: [{ text: '7' }] },
  // ])
  const [buttons] = useState([
    { radius: '40%', background: '#617df2' },
    { radius: '35%', background: '#afc8ff' },
    {
      radius: '30%', background: '#869cfa',
      pointer: true,
      fonts: [{ text: '开始', top: '-10px' }]
    }
  ])
  const myLucky = useRef()

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setry(prev => (prev + 1) % 356);
  //   }, 100);

  //   return () => clearInterval(interval); // 清除定时器
  // }, []);

//https://infomedia-image.oss-cn-beijing.aliyuncs.com/muzhicloud/9208750090/user/2024-11-05/c2779b5f-307e-8699-d340-8bcc0d081b3d.gif

  return (
    <div className={styles.container}>
      <img src="https://infomedia-image.oss-cn-beijing.aliyuncs.com/muzhicloud/9208750090/user/2024-11-05/13eb598b-fcbd-27d2-7458-5c168fbb9b16.gif"/>
      <div className={styles.img}>
      </div>
      <div className={styles.fire}/>
    </div>
  )
};

export default Empty;