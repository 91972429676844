import React, { useState, useEffect } from "react";
import {
  Button, Modal, Tag
} from 'antd';
import styles from './index.module.scss';
import { PllDatas } from "../../config/cubeconfig";
import _ from "lodash";


const PllSet = (props: any) => {
  const { visible, datas, onOk, onCancel } = props;
  const [chooseDatas, setChooseDatas] = useState<number[]>([]);

  useEffect(() => {
    if (visible) {
      setChooseDatas(datas);
    }
  }, [visible]);

  return (
    <Modal title='设置'
      width={650}
      visible={props.visible}
      onOk={() => { onOk && onOk(chooseDatas) }}
      onCancel={() => { onCancel && onCancel() }}
      footer={[
        <Button onClick={()=>{
          let tempChooseDatas = PllDatas.map(item=>item.index);
          setChooseDatas(tempChooseDatas);
        }}>全选</Button>,
        <Button onClick={()=>{
          let tempChooseDatas:number[] = [];
          setChooseDatas(tempChooseDatas);
        }}>全不选</Button>,
        <Button onClick={()=>{
          let tempChooseDatas = PllDatas.map(item=>item.index).filter(item=>!chooseDatas.includes(item));
          setChooseDatas(tempChooseDatas);
        }}>反选</Button>,
        <Button onClick={() => { onCancel && onCancel() }}>取消</Button>,
        <Button type="primary" onClick={() => { onOk && onOk(chooseDatas) }}>确认</Button>,
      ]}>
      <div className={styles.container} >
        {PllDatas.map(item => {
          return (
            <Tag key={`tag${item.index}`}
              style={{ margin: "5px" }}
              color={chooseDatas.includes(item.index) ? "#f50" : "red"}
              onClick={() => {
                let tempChooseDatas = _.cloneDeep(chooseDatas);
                if (chooseDatas.includes(item.index)) {
                  tempChooseDatas = chooseDatas.filter(o => o !== item.index);
                } else {
                  tempChooseDatas.push(item.index);
                }
                setChooseDatas(tempChooseDatas);
              }}>
              {`${item.index}.${item.title}`}
            </Tag>
          )
        })}
      </div>
    </Modal>
  )
};

export default PllSet;